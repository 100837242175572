/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as e } from "./chunks/tslib.es6.js";
import r from "./request.js";
import t from "./core/Collection.js";
import { referenceSetter as s } from "./core/collectionUtils.js";
import { JSONSupportMixin as a } from "./core/JSONSupport.js";
import { isClonable as o, clone as i } from "./core/lang.js";
import n from "./core/Loadable.js";
import { loadAll as l } from "./core/loadAll.js";
import p from "./core/Logger.js";
import { destroyMaybe as c } from "./core/maybe.js";
import { throwIfAborted as m } from "./core/promiseUtils.js";
import { isProtocolRelative as y, urlToObject as h } from "./core/urlUtils.js";
import { property as u } from "./core/accessorSupport/decorators/property.js";
import "./core/has.js";
import { subclass as d } from "./core/accessorSupport/decorators/subclass.js";
import { writer as f } from "./core/accessorSupport/decorators/writer.js";
import b from "./geometry/SpatialReference.js";
import L from "./portal/Portal.js";
import w from "./portal/PortalItem.js";
import { getBasemapTitle as g, esriBasemapDefinitions as I } from "./support/basemapDefinitions.js";
import S from "./support/BasemapStyle.js";
import { getLayerJSON as j } from "./webdoc/support/writeUtils.js";
var _;
let v = 0,
  x = _ = class extends a(n) {
    constructor(e) {
      super(e), this.id = null, this.portalItem = null, this.spatialReference = null, this.style = null, this.thumbnailUrl = null, this.title = "Basemap", this.id = Date.now().toString(16) + "-basemap-" + v++, this.baseLayers = new t(), this.referenceLayers = new t();
      const r = e => {
          e.parent && e.parent !== this && "remove" in e.parent && e.parent.remove(e), e.parent = this, "elevation" === e.type && p.getLogger(this).error(`Layer '${e.title}, id:${e.id}' of type '${e.type}' is not supported as a basemap layer and will therefore be ignored.`);
        },
        s = e => {
          e.parent = null;
        };
      this.addHandles([this.baseLayers.on("after-add", e => r(e.item)), this.referenceLayers.on("after-add", e => r(e.item)), this.baseLayers.on("after-remove", e => s(e.item)), this.referenceLayers.on("after-remove", e => s(e.item))]);
    }
    initialize() {
      this.when().catch(e => {
        p.getLogger(this).error("#load()", `Failed to load basemap (title: '${this.title}', id: '${this.id}')`, e);
      }), this.resourceInfo && this.read(this.resourceInfo.data, this.resourceInfo.context);
    }
    destroy() {
      const e = this.baseLayers.toArray();
      for (const t of e) t.destroy();
      const r = this.referenceLayers.toArray();
      for (const t of r) t.destroy();
      this.baseLayers.destroy(), this.referenceLayers.destroy(), this.portalItem = c(this.portalItem);
    }
    normalizeCtorArgs(e) {
      return e && "resourceInfo" in e && (this._set("resourceInfo", e.resourceInfo), delete (e = {
        ...e
      }).resourceInfo), e;
    }
    set baseLayers(e) {
      this._set("baseLayers", s(e, this._get("baseLayers")));
    }
    _writeBaseLayers(e, r, t) {
      const s = [];
      e ? (t = {
        ...t,
        layerContainerType: "basemap"
      }, this.baseLayers.forEach(e => {
        const r = j(e, t.webmap ? t.webmap.getLayerJSONFromResourceInfo(e) : null, t);
        null != r && s.push(r);
      }), this.referenceLayers.forEach(e => {
        const r = j(e, t.webmap ? t.webmap.getLayerJSONFromResourceInfo(e) : null, t);
        null != r && ("scene" !== e.type && (r.isReference = !0), s.push(r));
      }), r.baseMapLayers = s) : r.baseMapLayers = s;
    }
    set referenceLayers(e) {
      this._set("referenceLayers", s(e, this._get("referenceLayers")));
    }
    writeTitle(e, r) {
      r.title = e || "Basemap";
    }
    load(e) {
      return this.addResolvingPromise(this._loadFromSource(e)), Promise.resolve(this);
    }
    loadAll() {
      return l(this, e => {
        e(this.baseLayers, this.referenceLayers);
      });
    }
    clone() {
      const e = {
        id: this.id,
        title: this.title,
        portalItem: this.portalItem,
        baseLayers: this.baseLayers.map(e => o(e) ? e.clone() : e),
        referenceLayers: this.referenceLayers.map(e => o(e) ? e.clone() : e)
      };
      return this.loaded && (e.loadStatus = "loaded"), new _({
        resourceInfo: this.resourceInfo
      }).set(e);
    }
    read(e, r) {
      this.resourceInfo || this._set("resourceInfo", {
        data: e,
        context: r
      }), super.read(e, r);
    }
    write(e, r) {
      return e = e || {}, r?.origin || (r = {
        origin: "web-map",
        ...r
      }), super.write(e, r), !this.loaded && this.resourceInfo?.data.baseMapLayers && (e.baseMapLayers = this.resourceInfo.data.baseMapLayers.map(e => {
        const r = i(e);
        return r.url && y(r.url) && (r.url = `https:${r.url}`), r.templateUrl && y(r.templateUrl) && (r.templateUrl = `https:${r.templateUrl}`), r;
      })), e;
    }
    async _loadFromSource(e) {
      const {
        resourceInfo: r,
        portalItem: t,
        style: s
      } = this;
      m(e);
      const a = [];
      if (r) {
        const t = r.context ? r.context.url : null;
        if (a.push(this._loadLayersFromJSON(r.data, t, e)), r.data.id && !r.data.title) {
          const e = r.data.id;
          a.push(g(e).then(e => {
            e && this.read({
              title: e
            }, r.context);
          }));
        }
      } else t ? a.push(this._loadFromItem(t, e)) : s && a.push(this._loadFromStylesService(s, e));
      await Promise.all(a);
    }
    async _loadLayersFromJSON(e, r, t) {
      const s = this.resourceInfo?.context,
        a = this.portalItem?.portal || s?.portal || null,
        o = U[s?.origin || ""] ?? "web-map",
        {
          populateOperationalLayers: i
        } = await import("./layers/support/layersCreator.js"),
        n = [];
      if (m(t), e.baseMapLayers && Array.isArray(e.baseMapLayers)) {
        const t = {
            context: {
              ...s,
              origin: o,
              url: r,
              portal: a,
              layerContainerType: "basemap"
            },
            defaultLayerType: "DefaultTileLayer"
          },
          l = e => "web-scene" === o && "ArcGISSceneServiceLayer" === e.layerType || e.isReference,
          p = i(this.baseLayers, e.baseMapLayers.filter(e => !l(e)), t);
        n.push(p);
        const c = i(this.referenceLayers, e.baseMapLayers.filter(l), t);
        n.push(c);
      }
      await Promise.allSettled(n);
    }
    async _loadFromItem(e, r) {
      const t = await e.load(r),
        s = await t.fetchData("json", r),
        a = h(e.itemUrl ?? "");
      return this._set("resourceInfo", {
        data: s.baseMap ?? {},
        context: {
          origin: M[e.type || ""] ?? "web-map",
          portal: e.portal || L.getDefault(),
          url: a
        }
      }), this.read(this.resourceInfo.data, this.resourceInfo.context), this.read({
        spatialReference: s.spatialReference
      }, this.resourceInfo.context), this.read({
        title: e.title,
        thumbnailUrl: e.thumbnailUrl
      }, {
        origin: "portal-item",
        portal: e.portal || L.getDefault(),
        url: a
      }), this._loadLayersFromJSON(this.resourceInfo.data, a, r);
    }
    async _loadFromStylesService(e, t) {
      const s = `${e.serviceUrl}/${e.id}`,
        a = (await r(s, {
          query: {
            language: e.getLanguageParamValue(),
            places: e.places,
            worldview: e.worldview,
            token: e.apiKey
          },
          signal: t?.signal
        })).data,
        o = h(s);
      if (this._set("resourceInfo", {
        data: a.baseMap ?? {},
        context: {
          origin: "web-map",
          url: o
        }
      }), this.read(this.resourceInfo.data, this.resourceInfo.context), this.read({
        spatialReference: a.spatialReference
      }, this.resourceInfo.context), await this._loadLayersFromJSON(this.resourceInfo.data, o, t), e.apiKey) for (const r of [...this.baseLayers, ...this.referenceLayers]) "apiKey" in r && (r.apiKey = e.apiKey);
    }
    static fromId(e) {
      const r = I[e];
      return r ? r.itemId ? new _({
        portalItem: {
          id: r.itemId,
          portal: {
            url: "https://www.arcgis.com"
          }
        }
      }) : _.fromJSON(r, {
        origin: r.is3d ? "web-scene" : "web-map"
      }) : null;
    }
  };
e([u({
  json: {
    write: {
      ignoreOrigin: !0,
      target: "baseMapLayers",
      writer(e, r, t, s) {
        this._writeBaseLayers(e, r, s);
      }
    },
    origins: {
      "web-scene": {
        write: {
          ignoreOrigin: !0,
          target: {
            baseMapLayers: {
              type: t
            }
          },
          writer(e, r, t, s) {
            this._writeBaseLayers(e, r, s);
          }
        }
      }
    }
  }
})], x.prototype, "baseLayers", null), e([u({
  type: String,
  json: {
    origins: {
      "web-scene": {
        write: !0
      }
    }
  }
})], x.prototype, "id", void 0), e([u({
  type: w
})], x.prototype, "portalItem", void 0), e([u()], x.prototype, "referenceLayers", null), e([u({
  readOnly: !0
})], x.prototype, "resourceInfo", void 0), e([u({
  type: b
})], x.prototype, "spatialReference", void 0), e([u({
  type: S
})], x.prototype, "style", void 0), e([u()], x.prototype, "thumbnailUrl", void 0), e([u({
  type: String,
  json: {
    origins: {
      "web-scene": {
        write: {
          isRequired: !0
        }
      }
    }
  }
})], x.prototype, "title", void 0), e([f("title")], x.prototype, "writeTitle", null), x = _ = e([d("esri.Basemap")], x);
const M = {
    "Web Scene": "web-scene",
    "Web Map": "web-map",
    "Link Chart": "link-chart"
  },
  U = {
    "web-scene": "web-scene",
    "web-map": "web-map",
    "link-chart": "link-chart"
  },
  F = x;
export { F as default };